import React from 'react';
import PropTypes from 'prop-types';
// Components
import Earth from '../components/Earth';
import Logo from '../components/Logo';
// Utils
import myStyled from '../services/myStyled';

const StyledDefaultLayout = myStyled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content 1fr 80px',
  mx: 'auto',
  minHeight: '100vh',
  p: 0,
  position: 'relative',
});

const Title = myStyled('h1', {
  fontFamily: 'header',
  fontSize: 48,
  lineHeight: 1.04,
  mb: 24,
  mx: 24,
  textAlign: 'center',
});

const StyledContentArea = myStyled('section', (props) => {
  return {
    alignSelf: 'center',
    height: 'auto',
    pb: '80px',
    ...props.contentArea,
  };
});

const StyledBlurb = myStyled('div', {
  mb: 24,
  minHeight: '2rem',
  mx: 40,
  textAlign: 'center',
});

const EarthWrapper = myStyled('div', {
  height: '80px',
  overflow: 'hidden',
  textAlign: 'center',
});

function DefaultLayout({ children, contentArea, description, title, ...rest }) {
  return (
    <StyledDefaultLayout width={{ xs: '325px', md: '507px', lg: '636px' }} {...rest}>
      <Logo />

      <StyledContentArea contentArea={contentArea}>
        <Title mt={{ xs: 24 }}>{title}</Title>

        {description && <StyledBlurb>{description}</StyledBlurb>}

        {children}
      </StyledContentArea>

      <EarthWrapper>
        <Earth />
      </EarthWrapper>
    </StyledDefaultLayout>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.any,
  contentArea: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string,
};

export default DefaultLayout;
