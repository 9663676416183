import React from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
// Utils
import myStyled from '../services/myStyled';
// Assets
import panda from '../images/panda.gif';

const StyledImg = myStyled('img', {
  display: 'block',
  mx: 'auto',
});

function Awww() {
  return (
    <DefaultLayout title="This one pretty much speaks for itself">
      <StyledImg src={panda} alt="Panda Rolling" />
    </DefaultLayout>
  );
}

export default Awww;
