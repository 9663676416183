import { createGlobalStyle } from 'styled-components/macro';
import CrapcaseTextRegularWoff2 from '../fonts/CrapcaseText-Regular.woff2';
import CrapcaseTextRegularWoff from '../fonts/CrapcaseText-Regular.woff';
import CrapcaseTextRegularTtf from '../fonts/CrapcaseText-Regular.ttf';
import CrapcaseTextBoldWoff2 from '../fonts/CrapcaseText-Bold.woff2';
import CrapcaseTextBoldWoff from '../fonts/CrapcaseText-Bold.woff';
import CrapcaseTextBoldTtf from '../fonts/CrapcaseText-Bold.ttf';
import CrapcaseDisplayBlackItalicWoff2 from '../fonts/CrapcaseDisplay-BlackItalic.woff2';
import CrapcaseDisplayBlackItalicWoff from '../fonts/CrapcaseDisplay-BlackItalic.woff';
import CrapcaseDisplayBlackItalicTtf from '../fonts/CrapcaseDisplay-BlackItalic.ttf';
import CrapcaseDisplayMediumWoff2 from '../fonts/CrapcaseDisplay-Medium.woff2';
import CrapcaseDisplayMediumWoff from '../fonts/CrapcaseDisplay-Medium.woff';
import CrapcaseDisplayMediumTtf from '../fonts/CrapcaseDisplay-Medium.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Crapcase Text';
    src: url(${CrapcaseTextRegularWoff2}) format('woff2'),
      url(${CrapcaseTextRegularWoff}) format('woff'),
      url(${CrapcaseTextRegularTtf}) format('ttf');
  }

  @font-face {
    font-family: 'Crapcase Text Bold';
    src: url(${CrapcaseTextBoldWoff2}) format('woff2'),
      url(${CrapcaseTextBoldWoff}) format('woff'),
      url(${CrapcaseTextBoldTtf}) format('ttf');
  }

  @font-face {
    font-family: 'Crapcase Display Bold Italic';
    src: url(${CrapcaseDisplayBlackItalicWoff2}) format('woff2'),
      url(${CrapcaseDisplayBlackItalicWoff}) format('woff'),
      url(${CrapcaseDisplayBlackItalicTtf}) format('ttf');
  }

  @font-face {
    font-family: 'Crapcase Display Medium';
    src: url(${CrapcaseDisplayMediumWoff2}) format('woff2'),
      url(${CrapcaseDisplayMediumWoff}) format('woff'),
      url(${CrapcaseDisplayMediumTtf}) format('ttf');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    line-height: initial;
  }

  html {
    height: 100%;
  }

  body {
    background: ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.text.lightest};
    font-size: ${({ theme }) => theme.fontSizes[16]};
    font-family: ${({ theme }) => theme.fonts.base};
    font-weight: ${({ theme }) => theme.fontWeights.base};
    height: 100%;
    line-height: ${({ theme }) => theme.lineHeights.base};
    padding-bottom: 230px;
  }
`;

export default GlobalStyles;
