import React from 'react';
import PropTypes from 'prop-types';
// Utils
import myStyled from '../../../services/myStyled';

const StyledErrorMessage = myStyled('div', (props) => ({
  bottom: props.relative ? 0 : '-24px',
  left: '2px',
  color: 'cta.error',
  fontFamily: 'base',
  fontSize: 14,
  fontWeight: 'base',
  opacity: props.show ? 1 : 0,
  position: props.relative ? 'relative' : 'absolute',
  transition: 'all 250ms',
}));

function ErrorMessage({ children, relative, show }) {
  return (
    <StyledErrorMessage relative={relative} show={show}>
      {children}
    </StyledErrorMessage>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.string,
  relative: PropTypes.bool,
  show: PropTypes.bool,
};

export default ErrorMessage;
