import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Core
import Button from '../../core/Button';
// Utils
import myStyled from '../../../services/myStyled';
// Assets
import useLetter from './useLetter';

const StyledBlurb = myStyled('div', {
  fontFamily: 'headerMedium',
  fontSize: 18,
  mb: 32,
  minHeight: '2rem',
  textAlign: 'center',
});

const StyledTitle = myStyled('h1', {
  fontFamily: 'headerMedium',
  fontSize: 48,
  lineHeight: 1.04,
  mb: 16,
  textAlign: 'center',
});

const StyledResults = myStyled('div', {
  bg: 'accent.soft',
  borderRadius: 'form',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  my: 16,
  p: 16,
});

const StyledFillInTheBlank = myStyled('article', {
  color: 'text.base',
  lineHeight: 'tight',
  px: 24,
  py: 32,
  textAlign: 'left',
});

const StyledDiv = myStyled('div', {
  mb: 22,
});

function Results({ formData }) {
  const [letterWithComponents, letterForEmail] = useLetter(formData);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [formData]);

  // const subject = encodeURIComponent('Commit to Climate Action at COP27');

  function clickHandler() {
    // eslint-disable-next-line prettier/prettier
    window.open(
      `mailto:?subject=Climate Action&body=${encodeURIComponent(letterForEmail)}`,
      '_blank'
    );
  }

  return (
    <StyledResults>
      <StyledFillInTheBlank>
        <StyledTitle>Your message</StyledTitle>
        <StyledBlurb>
          When you’re ready to send, just scroll to the button below and we’ll toss it over to your
          email client automagically.
        </StyledBlurb>

        {letterWithComponents.map((m, i) => (
          <StyledDiv key={`paragraph-${i}`} children={m} />
        ))}
      </StyledFillInTheBlank>

      <Button mb={32} onClick={clickHandler}>
        Send As Email
      </Button>
    </StyledResults>
  );
}

Results.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default Results;
