import React from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
// Components
import YouTube, { breakpoints } from '../components/YouTube';

function Grow() {
  return (
    <DefaultLayout
      description="Super-fast fungi, captured by filmmaker Neil Bromhall."
      title="Talk about magic mushrooms"
      width={{ xs: '328px', md: '507px', lg: '636px' }}
    >
      <YouTube
        src="https://www.youtube.com/embed/KaKMugW8Bqc"
        title="Talk about magic mushrooms"
        height={breakpoints.height}
        width={breakpoints.width}
      />
    </DefaultLayout>
  );
}

export default Grow;
