import React from 'react';
import PropTypes from 'prop-types';
// Services
import myStyled from '../../../services/myStyled';

const StyledUl = myStyled('ul', {
  '&&': {
    pl: 16,
  },
});

const StyledLi = myStyled('li', {
  pl: 4,
});

function ListItem({ children }) {
  return (
    <StyledUl>
      <StyledLi children={children} />
    </StyledUl>
  );
}

ListItem.propTypes = {
  children: PropTypes.any,
};

export default ListItem;
