// Constants since these values are used repeatedly
export const END = 'end';
export const LIST_ITEM = 'list_item';
export const MIDDLE = 'middle';
export const START = 'start';
export const VALUE = 'value';
export const VALUE_BLOCK = 'VALUE_BLOCK';

// Overengineered array that holds all the text for the letter and uses the constants above to
// signify what kind of data is being held
export default (formData) => [
  [
    [START, `I live in`],
    [VALUE, formData.postcode.value],
    [
      END,
      `and I am writing as your constituent to ask that you take a stand against the global climate crisis.`,
    ],
  ],

  [
    [START, `As a`],
    [VALUE, formData.checkboxFeeling.value.formattedString],
    [MIDDLE, `citizen interested in`],
    [VALUE, `${formData.checkboxCare.value.formattedString},`],
    [
      END,
      `climate change is a pressing priority. Even as the devastating impacts of climate change are increasingly felt around the world, our government has not acted decisively enough to prevent irreversible damage to our environment.`,
    ],
  ],

  [
    [START, `While${formData.geo.value.formattedString === 'Australia' ? '' : ' the'}`],
    [VALUE, formData.geo.value.formattedString],
    [
      END,
      `and its partners achieved some genuine progress at COP27, international climate pledges are still not sufficient. Analysis published by the UN IPCC shows even with these pledges, the world is on track for a disastrous 3.2°C of warming by the end of this century.
      `,
    ],
  ],

  [
    [START, `I am asking you to please call on the`],
    [
      VALUE,
      formData.geo.value.formattedString === 'United States of America'
        ? 'President '
        : 'Prime Minister ',
    ],
    [END, `to continue to prioritise our country's commitment to climate action by:`],
  ],

  [
    [
      LIST_ITEM,
      `Pressing member countries to immediately implement more ambitious 2030 emission reduction plans, in line with their fair share of 1.5°C.`,
    ],
    [END, ''],
  ],

  [
    [
      LIST_ITEM,
      `Assuring that ${formData.geo.value.formattedString === 'Australia' ? '' : 'the '}`,
    ],
    [VALUE, formData.geo.value.formattedString],
    [
      END,
      `is on track to its own net zero strategy, backed by detailed plans and a net zero test for all government spending.`,
    ],
  ],

  [
    [
      START,
      `Our best hope to slow the threat is immediate action - for courageous leaders to step up and commit to the work that must be done to protect our future. Now is the time to rise to the moment and demand action.`,
    ],
    [END, ''],
  ],

  [
    [VALUE, formData.radioButton.value],

    [
      VALUE_BLOCK,
      `${formData.firstName.value} ${!formData.surname.value ? '' : formData.surname.value}`,
    ],
  ],
];
