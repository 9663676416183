const radioButtonData = [
  'Sincerely,',
  'Urgently,',
  'Respectfully,',
  'Appreciatively,',
  'Global Warmly,',
];

const checkboxDataFeeling = ['concerned', 'angry', 'distressed', 'annoyed', 'anxious'];

const checkboxDataCare = [
  'global equity',
  'food & water security',
  'biodiversity',
  'the fate of humanity',
  'all of the above',
];

const formErrorMessages = {
  firstName: 'Please enter a first name',
  checkboxFeeling: 'Please select any of the options',
  checkboxCare: 'Please select any of the options',
  radioButton: 'Please select one of the options',
  geo: 'Please select a country',
  postcode: 'Please enter a postcode',
};

export default {
  checkboxDataCare,
  radioButtonData,
  checkboxDataFeeling,
  formErrorMessages,
};
