import React, { useState } from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
import Form from '../components/Protect/Form';
import Results from '../components/Protect/Results';
// Core
import Button from '../components/core/Button';
// Components
import ChevronLeftButton from '../components/Protect/ChevronLeftButton';
// Utils
import myStyled from '../services/myStyled';

const StyledBlurb = myStyled('div', (props) => ({
  fontSize: 18,
  mb: props.letterCreated ? 16 : 48,
  minHeight: '2rem',
  textAlign: 'center',
}));

const Title = myStyled('h1', {
  fontFamily: 'header',
  fontSize: 48,
  lineHeight: 1.04,
  mb: 16,
  mt: 48,
});

const StyledButtonWrapper = myStyled('div', {
  alignItems: 'center',
  mb: 48,
});

function Protect() {
  const [formData, setFormData] = useState(false);
  const [saveData, setSaveData] = useState();
  // const shouldRedirect = window && !window.location.hostname.includes('localhost');

  // The form isn't ready so this is in place to redirect users temporarily
  // if (shouldRedirect) {
  //   window.location = 'https://whogivesacrap.org/products/earth-limited-edition';
  // }

  function goBackHandler() {
    setSaveData({ ...formData });
    setFormData(false);
  }

  function openRepLink() {
    const { url } = formData.geo.value;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <DefaultLayout textAlign={{ xs: 'left', lg: 'center' }} contentArea={{ alignSelf: 'start' }}>
      {formData && <ChevronLeftButton onClick={goBackHandler}>Go back</ChevronLeftButton>}

      <Title textAlign={{ xs: 'left', md: 'center' }} fontSize={{ xs: '50px', lg: '68px' }}>
        {formData !== false ? 'Here you go!' : 'Push the envelope'}
      </Title>

      <StyledBlurb textAlign={{ xs: 'left', md: 'center' }} letterCreated={formData !== false}>
        {formData !== false ? (
          'Not sure who to send your letter to? Contact info for your local rep can be found with a quick click below!'
        ) : (
          <>
            Fill in the form below and we&apos;ll whip up a letter for your local elected official,
            urging them to take climate action.
          </>
        )}
      </StyledBlurb>

      {formData && (
        <StyledButtonWrapper display={{ md: 'flex' }}>
          <Button
            backgroundColor="secondary.base"
            color="primary.base"
            fontSize={16}
            height="3rem"
            onClick={openRepLink}
            px={18}
            width="8rem"
          >
            Find my rep
          </Button>
        </StyledButtonWrapper>
      )}

      {!formData && <Form submitHandlerFn={setFormData} savedData={saveData} />}

      {formData && <Results formData={formData} />}
    </DefaultLayout>
  );
}

export default Protect;
