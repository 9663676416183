import React from 'react';
import PropTypes from 'prop-types';
// Utils
import myStyled from '../../../services/myStyled';

const StyledButton = myStyled('button', {
  backgroundColor: 'primary.base',
  borderRadius: 'rounded',
  color: 'text.lightest',
  display: 'inline-block',
  fontFamily: 'baseBold',
  fontSize: 18,
  fontWeight: 'base',
  height: '2.875rem',
  lineHeight: 'tightest',
  mx: 'auto',
  maxWidth: '11.875rem',
  width: '100%',
});

function Button({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

Button.propTypes = {
  children: PropTypes.any,
};

export default Button;
