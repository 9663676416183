import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import {
  Mesh,
  MeshPhongMaterial,
  Scene,
  SphereGeometry,
  SpotLight,
  PerspectiveCamera,
  TextureLoader,
  WebGLRenderer,
} from 'three';
// Assets
import earthMapUrl from '../../images/wgac-earth.jpeg';

function Earth() {
  // Need to be able to have useEffect fire a single time on mount. This will help.
  const mounted = useRef(false);
  // Three uses this to render the earth to the canvas
  const [renderer, setRenderer] = useState(false);
  // Sets where the camera is to look at the earth
  const camera = useMemo(() => new PerspectiveCamera(70, 1, 1, 100), []);
  // What everything here is going to exist on
  const scene = useMemo(() => new Scene(), []);
  // The sphere shape
  const geometry = useMemo(() => new SphereGeometry(13, 100, 100), []);
  // The type of material to apply to the sphere
  const material = useMemo(() => new MeshPhongMaterial(), []);
  // You got me on this one, I'm not sure
  const mesh = useMemo(() => new Mesh(geometry, material), [geometry, material]);
  // The color and type of light to shine on the sphere so we can see it
  const spotLight = useMemo(() => new SpotLight(0xffffff), []);

  // Honestly, this probably isn't necessary since I'm heavily constraining the size of the earth,
  // but it's practical to keep it
  const resize = useCallback(() => {
    const width = renderer.domElement.clientWidth;
    const height = renderer.domElement.clientHeight;

    renderer.setSize(width, height, false);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  }, [camera, renderer]);

  // Calls itself with requestAnimationFrame giving the illusion of animating
  const animateEarth = useCallback(() => {
    if (camera && mesh?.rotation && renderer && scene) {
      resize();
      mesh.rotation.y += 0.0015; // Speed of rotating animation. Smaller number means slower.
      renderer.render(scene, camera);
      requestAnimationFrame(animateEarth);
    }
  }, [camera, mesh.rotation, renderer, resize, scene]);

  useEffect(() => {
    const canvas = document.getElementById('earth');

    // Renderer can only be created when the canvas is present
    if (canvas && !renderer) {
      setRenderer(new WebGLRenderer({ alpha: true, canvas }));
    }

    if (!mounted.current && renderer) {
      camera.position.z = 25;
      material.map = new TextureLoader().load(earthMapUrl);
      mesh.rotation.x += 0.5;
      scene.add(mesh);

      spotLight.position.set(-1000, 1200, 500);
      spotLight.intensity = 1.25;
      spotLight.castShadow = true;
      spotLight.shadow.mapSize.width = 1024;
      spotLight.shadow.mapSize.height = 1024;
      spotLight.shadow.camera.near = 500;
      spotLight.shadow.camera.far = 4000;
      spotLight.shadow.camera.fov = 30;
      scene.add(spotLight);

      mounted.current = true;

      animateEarth();
    }
  }, [animateEarth, camera.position, material, mesh, mounted, renderer, scene, spotLight]);

  return <canvas width="180" height="180" id="earth" />;
}

export default Earth;
