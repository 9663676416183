import React from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
// Components
import FunFactGenerator from '../components/FunFactGenerator/FunFactGenerator';

function DialAFact() {
  return (
    <DefaultLayout
      contentArea={{ height: '650px' }}
      description="Fascinating Earth facts, scientifically tested to squash those awkward silences."
      title="Have you heard?"
      width={{ xs: '324px', lg: '413px' }}
    >
      <FunFactGenerator />
    </DefaultLayout>
  );
}

DialAFact.propTypes = {};

export default DialAFact;
