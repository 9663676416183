import auIcon from '../../../images/geo-au-flag.png';
import usIcon from '../../../images/geo-us-flag.png';
import ukIcon from '../../../images/geo-uk-flag.png';

export default ({ placeholder = false, value }) => {
  const geoOptions = [
    {
      icon: auIcon,
      name: 'Australia',
      value: 'au',
      repLink: 'https://www.aph.gov.au/Senators_and_Members',
    },
    {
      icon: ukIcon,
      name: 'United Kingdom',
      value: 'uk',
      repLink: 'https://members.parliament.uk/members/Commons',
    },
    {
      icon: usIcon,
      name: 'United States of America',
      value: 'us',
      repLink: 'https://www.congress.gov/members/find-your-member',
    },
  ];

  // If there's a placeholder then add it to the beginning of the list
  if (placeholder) {
    geoOptions.unshift({
      icon: false,
      name: placeholder,
      value: false,
      repLink: false,
    });
  }

  // Once something other than the placeholder  is chosen, remove the placeholder from the list
  if (value !== false) {
    geoOptions.shift();
  }

  return geoOptions;
};
