import React from 'react';
import PropTypes from 'prop-types';
import myStyled from '../../../services/myStyled';

const StyledSvg = myStyled('svg', (props) => ({
  color: 'text.light',
  fill: 'text.light',
  transition: 'transform 0.5s ease',
  transform: props.opened ? 'rotate(0)' : 'rotate(180deg)',
}));

function DropdownArrow({ opened }) {
  return (
    <StyledSvg opened={opened} width={10} height={10} viewBox="0 0 306 306">
      <path d="M153 58.65l-153 153 35.7 35.7L153 130.05l117.3 117.3 35.7-35.7z" />
    </StyledSvg>
  );
}

DropdownArrow.propTypes = {
  opened: PropTypes.bool,
};

export default DropdownArrow;
