import React from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
// Utils
import myStyled from '../services/myStyled';

const SpotifyPlaylist = myStyled('iframe', {
  borderRadius: 'base',
  height: '483px',
  width: '100%',
});

function RainForestRadio() {
  return (
    <DefaultLayout
      description="Unwind with a selection of soothing sounds from the Amazon. "
      title="Welcome to the Jungle"
    >
      <SpotifyPlaylist
        src="https://open.spotify.com/embed/playlist/1EhnHfhmbaG02w1OixHagm?utm_source=generator&theme=0"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </DefaultLayout>
  );
}

RainForestRadio.propTypes = {};

export default RainForestRadio;
