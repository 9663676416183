import React from 'react';
// Utils
import myStyled from '../../services/myStyled';
// Breakpoints
import { height, width } from './breakpoints';

const StyledYouTube = myStyled('iframe', {
  display: 'block',
  mx: 'auto',
});

function YouTube({ ...rest }) {
  return (
    <StyledYouTube
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      src="https://www.youtube.com/embed/t_S_cN2re4g"
      title="YouTube video player"
      height={height}
      width={width}
      {...rest}
    />
  );
}

export default YouTube;
