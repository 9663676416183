import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
// Pages
import Awww from './pages/Awww';
import Deep from './pages/Deep';
import Live from './pages/Live';
import Grow from './pages/Grow';
import Protect from './pages/Protect';
import DialAFact from './pages/DialAFact';
import RainForestRadio from './pages/RainForestRadio';
// Theme
import theme from './theme';
// Global styles
import GlobalStyles from './components/GlobalStyles';

const routes = [
  { path: 'awww', element: <Awww /> },
  { path: 'deep', element: <Deep /> },
  { path: 'dialafact', element: <DialAFact /> },
  { path: 'grow', element: <Grow /> },
  { path: 'live', element: <Live /> },
  { path: 'protect', element: <Protect /> },
  { path: 'rainforestradio', element: <RainForestRadio /> },
];

function randomPath() {
  const randomNumber = Math.floor(Math.random(routes.length) * 7);
  return routes[randomNumber].path;
}

function App() {
  const isRoot = window && window.location.pathname === '/';

  if (isRoot && window) {
    window.location = randomPath();
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <Router>
        <Routes>
          {routes.map(({ element, path }) => (
            <Route element={element} path={path} key={path} />
          ))}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
