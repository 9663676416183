import React from 'react';
import PropTypes from 'prop-types';
// Utils
import myStyled from '../../../services/myStyled';
// Assets
import checkboxOff from '../../../images/checkbox-off.png';
import checkboxOn from '../../../images/checkbox-on.png';

const StyledCheckbox = myStyled('div', {
  alignItems: 'center',
  display: 'flex',
  my: 6,
});

const StyledInputWrapper = myStyled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '21px',
  position: 'relative',
  width: '20px',
});

const StyledInput = myStyled(
  'input',
  {
    cursor: 'pointer',
    height: '100%',
    opacity: 0,
    width: '100%',
  },
  { attrs: { type: 'checkbox' } }
);

const FauxCheck = myStyled('img', {
  display: 'block',
  height: '100%',
  left: '0',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0',
  width: '100%',
});

const StyledLabel = myStyled('label', {
  color: 'text.base',
  cursor: 'pointer',
  fontFamily: 'base',
  lineHeight: 'tighter',
  pl: 16,
});

function Checkbox({ buttonValue, checked, name, setValue }) {
  return (
    <StyledCheckbox>
      <StyledInputWrapper>
        <StyledInput
          checked={checked}
          id={`id-${buttonValue}`}
          name={name}
          onChange={() => setValue(buttonValue)}
          value={buttonValue}
        />
        {checked && <FauxCheck src={checkboxOn} />}
        {!checked && <FauxCheck src={checkboxOff} />}
      </StyledInputWrapper>

      <StyledLabel htmlFor={`id-${buttonValue}`}>{buttonValue}</StyledLabel>
    </StyledCheckbox>
  );
}

Checkbox.propTypes = {
  buttonValue: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  setValue: PropTypes.func,
};

export default Checkbox;
