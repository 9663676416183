import { useState, useMemo, useEffect } from 'react';
import fieldsData from '../Protect/Form/fieldsData';

const useForm = (savedForm) => {
  // Validation messages
  const {
    formErrorMessages: {
      radioButton,
      firstName,
      surname,
      geo,
      postcode,
      checkboxFeeling,
      checkboxCare,
    },
  } = fieldsData;

  const [formData, setFormData] = useState({
    checkboxFeeling: {
      value: { data: [], formattedString: '' },
      error: checkboxFeeling,
    },
    checkboxCare: {
      value: { data: [], formattedString: '' },
      error: checkboxCare,
    },
    geo: {
      value: { url: false, formattedString: '' },
      error: geo,
    },
    radioButton: { value: '', error: radioButton },
    firstName: { value: '', error: firstName },
    surname: { value: '', error: surname },
    postcode: { value: '', error: postcode },
  });

  useEffect(() => {
    if (savedForm) {
      setFormData(savedForm);
    }
  }, [savedForm]);

  function validate(value, name) {
    if (!value || (typeof value === 'object' && !value.formattedString)) {
      return fieldsData.formErrorMessages[name];
    }
    // Add more validation here if needed
    return '';
  }

  function handleSetValue(value, name) {
    setFormData({
      ...formData,
      [name]: { value, error: validate(value, name) },
    });
  }

  const errors = useMemo(() => Object.values(formData).filter((item) => item.error), [formData]);

  return {
    formData,
    handleSetValue,
    hasErrors: !!errors.length,
  };
};

export default useForm;
