import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
// Core
import Button from '../../core/Button';
import CheckboxGroup from '../../core/CheckboxGroup';
import ErrorMessage from '../../core/ErrorMessage/ErrorMessage';
import RadioButtonGroup from '../../core/RadioButtonGroup';
import TextInput from '../../core/TextInput/TextInput';
// Components
import GeoDropdown from '../GeoDropdown';
// Utils
import myStyled from '../../../services/myStyled';
// Assets
import fieldsData from './fieldsData';
// Hooks
import useForm from '../../hooks/useForm';

const StyledForm = myStyled('div', {
  bg: 'accent.soft',
  borderRadius: 'form',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  my: 16,
  p: 16,
});

const StyledHeading = myStyled('h2', {
  color: 'text.base',
  fontFamily: 'headerMedium',
  fontSize: 32,
  lineHeight: 'tighter',
  m: 16,
  mb: 40,
});

function Form({ submitHandlerFn, savedData }) {
  const [showErrors, setShowErrors] = useState(false);
  const { formData, handleSetValue, hasErrors } = useForm(savedData);

  const { checkboxFeeling, checkboxCare, radioButton, firstName, geo, postcode, surname } =
    formData;

  const handleSubmit = useCallback(() => {
    if (hasErrors) {
      setShowErrors(true);
    } else {
      submitHandlerFn(formData);
    }
  }, [formData, hasErrors, submitHandlerFn]);

  return (
    <StyledForm>
      <StyledHeading> What matters to you? </StyledHeading>

      <CheckboxGroup
        data={fieldsData.checkboxDataFeeling}
        error={showErrors ? !!checkboxFeeling.error : false}
        errorMessage={checkboxFeeling.error}
        name="checkboxFeeling"
        setValue={handleSetValue}
        title="I'm feeling"
        value={checkboxFeeling.value.data}
      />

      <CheckboxGroup
        data={fieldsData.checkboxDataCare}
        error={showErrors ? !!checkboxCare.error : false}
        errorMessage={checkboxCare.error}
        name="checkboxCare"
        setValue={handleSetValue}
        title="I care about"
        value={checkboxCare.value.data}
      />

      <RadioButtonGroup
        data={fieldsData.radioButtonData}
        error={showErrors ? !!radioButton.error : false}
        errorMessage={radioButton.error}
        name="radioButton"
        setValue={handleSetValue}
        title="Yours"
        value={radioButton.value}
      />

      <TextInput
        error={showErrors ? !!firstName.error : false}
        errorMessage={firstName.error}
        label="First Name"
        name="firstName"
        placeholder="Required"
        setValue={handleSetValue}
        value={firstName.value}
      />

      <TextInput
        error={showErrors ? !!surname.error : false}
        errorMessage={surname.error}
        label="Surname / Last Name"
        name="surname"
        placeholder="Optional"
        setValue={handleSetValue}
        value={surname.value}
      />

      <GeoDropdown
        error={showErrors ? !!geo.error : false}
        errorMessage={geo.error}
        name="geo"
        placeholder="Select Option"
        setValue={handleSetValue}
        value={geo.value.url}
      />

      <TextInput
        error={showErrors ? !!postcode.error : false}
        errorMessage={postcode.error}
        label="Postal / Zip code"
        name="postcode"
        placeholder="Postal / Zip code"
        setValue={handleSetValue}
        value={postcode.value}
      />

      <ErrorMessage show={false} relative>
        Woops! Must select or complete all required fields to continue
      </ErrorMessage>

      <Button backgroundColor="primary.base" color="primary.accent" my={20} onClick={handleSubmit}>
        Create my letter
      </Button>
    </StyledForm>
  );
}

Form.propTypes = {
  submitHandlerFn: PropTypes.func,
  savedData: PropTypes.object,
};

export default Form;
