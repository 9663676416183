import React from 'react';
import PropTypes from 'prop-types';
// Utils
import myStyled from '../../../services/myStyled';
// Assets
import radioOff from '../../../images/radio-off.png';
import radioOn from '../../../images/radio-on.png';

const StyledRadioButton = myStyled('div', {
  alignItems: 'center',
  display: 'flex',
  my: 6,
});

const StyledInputWrapper = myStyled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '21px',
  position: 'relative',
  width: '20px',
});

const StyledInput = myStyled(
  'input',
  {
    cursor: 'pointer',
    height: '100%',
    opacity: 0,
    width: '100%',
  },
  { attrs: { type: 'radio' } }
);

const FauxCheck = myStyled('img', {
  display: 'block',
  height: '100%',
  left: '0',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0',
  width: '100%',
});

const StyledLabel = myStyled('label', {
  color: 'text.base',
  cursor: 'pointer',
  fontFamily: 'base',
  lineHeight: 'tighter',
  pl: 16,
});

function RadioButton({ buttonValue, checked, name, setValue, formStateName }) {
  return (
    <StyledRadioButton>
      <StyledInputWrapper>
        <StyledInput
          checked={checked}
          id={`id-${buttonValue}`}
          name={name}
          onChange={() => setValue(buttonValue, formStateName)}
          value={buttonValue}
        />
        {checked && <FauxCheck src={radioOn} />}
        {!checked && <FauxCheck src={radioOff} />}
      </StyledInputWrapper>

      <StyledLabel htmlFor={`id-${buttonValue}`}>{buttonValue}</StyledLabel>
    </StyledRadioButton>
  );
}

RadioButton.propTypes = {
  buttonValue: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  setValue: PropTypes.func,
  formStateName: PropTypes.string,
};

export default RadioButton;
