import React from 'react';
import PropTypes from 'prop-types';
// Core
import ErrorMessage from '../ErrorMessage/ErrorMessage';
// Utils
import myStyled from '../../../services/myStyled';

const StyledTextInputContainer = myStyled('div', {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  mb: 40,
  position: 'relative',
});

const StyledLabel = myStyled('label', {
  color: 'text.base',
  fontFamily: 'baseBold',
  fontSize: 16,
  mb: 4,
});

const StyledTextInput = myStyled('input', (props) => ({
  border: '1px solid',
  borderColor: props.error ? 'cta.error' : 'neutrals.light',
  borderRadius: 'form',
  fontSize: 16,
  fontFamily: 'base',
  height: '2.75rem',
  p: 8,
  transition: 'border-color 250ms',
  width: '100%',
}));

function TextInput({ error, errorMessage, setValue, label, placeholder, name, value }) {
  // Handlers
  function handleChange(event) {
    setValue(event.target.value, name);
  }

  return (
    <StyledTextInputContainer>
      <StyledLabel> {label} </StyledLabel>
      <StyledTextInput
        error={error}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />

      <ErrorMessage show={error}>{errorMessage}</ErrorMessage>
    </StyledTextInputContainer>
  );
}

TextInput.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default TextInput;
