import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// Components
import Checkbox from './Checkbox';
// Core
import ErrorMessage from '../ErrorMessage/ErrorMessage';
// Utils
import myStyled from '../../../services/myStyled';

const regex = /[^a-zA-Z\d\s:]/;

const StyledCheckboxGroup = myStyled('div', {
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  mb: 32,
});

const StyledTitle = myStyled('h5', {
  color: 'text.base',
  fontFamily: 'baseBold',
  fontSize: 20,
  fontWeight: 'heavy',
  lineHeight: 'tighter',
  pb: 14,
  textAlign: 'left',
});

function CheckboxGroup({ data, error, errorMessage, title = '', setValue, value, ...props }) {
  const { name: formStateName } = props;
  const name = useMemo(() => title.replace(regex, '').toLowerCase(), [title]);

  function changeHandler(v) {
    function handleAndPrefix(dataSet) {
      let arrData = dataSet;

      if (arrData.includes('all of the above')) {
        arrData = arrData.filter((item) => !item.includes('all of the above'));
      }

      if (arrData.length > 1) {
        // Remove prev instance of 'and'
        arrData = arrData.filter((item) => !item.includes('and'));
        // Add next instance of 'and'
        arrData.splice(arrData.length - 1, 0, 'and');
      }

      const formattedString = arrData
        .join(arrData.length > 3 ? ', ' : ' ')
        .replace(
          ...(arrData.length > 3
            ? ['and, ', 'and ']
            : arrData.length > 2
            ? [', and', ' and']
            : ['and, ', ''])
        );
      return formattedString;
    }

    let checkedState = value.includes(v)
      ? // Remove single
        [...value, v].filter((item) => !v.includes(item))
      : v === 'all of the above'
      ? // Check all
        data
      : // Add single
        [...value, v];

    // Uncheck 'all of the above' when not all items are checked
    if (checkedState.includes('all of the above') && checkedState.length < data.length) {
      checkedState = checkedState.filter((item) => !item.includes('all of the above'));
    }

    setValue({ data: checkedState, formattedString: handleAndPrefix(checkedState) }, formStateName);
  }

  return (
    <StyledCheckboxGroup>
      {title.length > 0 && <StyledTitle>{title}</StyledTitle>}

      {data.map((d, i) => {
        return (
          <Checkbox
            buttonValue={d}
            checked={value.includes(d)}
            name={name}
            key={`checkbox-${i}`}
            setValue={changeHandler}
          />
        );
      })}

      <ErrorMessage show={error}>{errorMessage}</ErrorMessage>
    </StyledCheckboxGroup>
  );
}

CheckboxGroup.propTypes = {
  data: PropTypes.array,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  setValue: PropTypes.func,
};

export default CheckboxGroup;
