import React from 'react';
// Layouts
import DefaultLayout from '../layouts/DefaultLayout';
// Components
import YouTube, { breakpoints } from '../components/YouTube';

function Deep() {
  return (
    <DefaultLayout
      description="A ten hour deep dive into the watery weirdness of our world’s oceans, courtesy of BBC Earth."
      title="Don’t hold your breath"
      width={{ xs: '328px', md: '507px', lg: '636px' }}
    >
      <YouTube
        src="https://www.youtube.com/embed/t_S_cN2re4g"
        title="Don’t hold your breath"
        height={breakpoints.height}
        width={breakpoints.width}
      />
    </DefaultLayout>
  );
}

Deep.propTypes = {};

export default Deep;
