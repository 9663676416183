import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// Core
import ErrorMessage from '../ErrorMessage/ErrorMessage';
// Components
import RadioButton from './RadioButton';
// Utils
import myStyled from '../../../services/myStyled';

const regex = /[^a-zA-Z\d\s:]/;

const StyledRadioButtonGroup = myStyled('div', {
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  mb: 40,
  position: 'relative',
});

const StyledTitle = myStyled('h5', {
  color: 'text.base',
  fontFamily: 'baseBold',
  fontSize: 20,
  fontWeight: 'heavy',
  lineHeight: 'tighter',
  pb: 14,
  textAlign: 'left',
});

function RadioButtonGroup({ data, error, errorMessage, title = '', setValue, value, ...props }) {
  const name = useMemo(() => title.replace(regex, '').toLowerCase(), [title]);
  const { name: formStateName } = props;

  return (
    <StyledRadioButtonGroup>
      {title.length > 0 && <StyledTitle>{title}</StyledTitle>}

      <ErrorMessage show={error}>{errorMessage}</ErrorMessage>

      {data.map((d, i) => (
        <RadioButton
          buttonValue={d}
          name={name}
          key={`radio-${i}`}
          setValue={setValue}
          formStateName={formStateName}
          checked={data[i] === value}
        />
      ))}
    </StyledRadioButtonGroup>
  );
}

RadioButtonGroup.propTypes = {
  data: PropTypes.array,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  name: PropTypes.string,
};

export default RadioButtonGroup;
