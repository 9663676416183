/* eslint-disable prefer-destructuring */

const breakpoints = ['0', '375px', '768px', '1440px'];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];

const radii = {
  base: '8px',
  form: '4px',
  rounded: '1000px',
};

const colors = {
  accent: {
    soft: '#F5F0E0',
  },
  cta: {
    error: '#EA4335',
  },
  text: {
    lightest: '#ffffff',
    light: '#3f3f3f',
    base: '#303030',
  },
  primary: {
    base: '#587CFF',
  },
  secondary: {
    base: '#D7F6A4',
  },
  neutrals: {
    lightest: '#ffffff',
    light: '#d8d8d8',
  },
};

const fonts = {
  base: 'Crapcase Text',
  baseBold: 'Crapcase Text Bold',
  headerMedium: 'Crapcase Display Medium',
  header: 'Crapcase Display Bold Italic',
};

const fontSizes = {
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  22: '1.375rem',
  26: '1.625rem',
  32: '2rem',
  48: '2rem',
};

const fontWeights = {
  base: 400,
  heavy: 500,
};

const lineHeights = {
  tightest: 1.2,
  tighter: 1.25,
  tight: 1.35,
  base: 1.54,
};

const space = {
  0: 0,
  1: '0.0625rem',
  2: '0.125rem',
  4: '0.25rem',
  8: '0.5rem',
  12: '0.75rem',
  16: '1rem',
  20: '1.25rem',
  24: '1.5rem',
  32: '2rem',
  40: '2.5rem',
  64: '4rem',
};

export default {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  space,
};
