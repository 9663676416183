const facts = [
  { index: 0, title: 'Weasels, incorporated', fact: `A group of ferrets is called a “business.”` },
  {
    index: 1,
    title: 'Shell freezes over',
    fact: `Painted turtles survive frozen waters in winter by breathing through their bums.`,
  },
  {
    index: 2,
    title: 'Rat’s all, folks',
    fact: `Rat pups are ticklish — they even giggle if you get ‘em in the right spot.`,
  },
  { index: 3, title: 'Zero carbon emission', fact: `Kangaroos can’t fart.` },
  { index: 4, title: 'Tushin’ for cushion', fact: `Baby giraffes use their own bums as pillows.` },
  { index: 5, title: 'Coming up for air', fact: `Oceans produce up to 85% of the Earth's oxygen.` },
  { index: 6, title: 'Wham! was already taken', fact: `A group of rhinos is called a “crash.”` },
  {
    index: 7,
    title: 'You wave goodbye, they wave yellow',
    fact: `The word "banana" originates from an Arabic word “banan” which means finger. A bunch of bananas is called a hand.`,
  },
  {
    index: 8,
    title: 'Sound the alarm',
    fact: `When Arcadia trees are threatened by hungry herbivores, they emit ethylene gas that travels through the air, warning other trees to produce toxic tannins for self-defence!`,
  },
  {
    index: 9,
    title: 'Loos for shrews',
    fact: `A pitcher plant in Borneo has evolved into a loo for tree shrews. The plant makes nectar to attract them, and as the shrew's poo in the pitcher, it feeds the plant.`,
  },
  {
    index: 10,
    title: 'Stink bugs',
    fact: `Insects fart — they’re just too tiny for us to smell them.`,
  },
  {
    index: 11,
    title: 'Mötley Chëw',
    fact: `Termites chew through wood faster when they hear heavy metal.`,
  },
  {
    index: 12,
    title: 'Crickets are thermometers',
    fact: `If you count the number of times a cricket chirps in 25 seconds, divide by three, then add four, you’ll get the current temperature in Celcius.`,
  },
  {
    index: 13,
    title: 'Don’t forget leg day',
    fact: `Caterpillars have more than four times the number of muscles in their bodies as humans.`,
  },
  {
    index: 14,
    title: 'It must be all the sugar',
    fact: `In flight, hummingbirds have an average heart rate of 1200 bpm.`,
  },
  {
    index: 15,
    title: 'Sleeping with the fishes',
    fact: `When dolphins sleep, half of their brain stays awake.`,
  },
];

export default facts;
