import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Core
import ErrorMessage from '../../core/ErrorMessage/ErrorMessage';
// Components
import DropdownArrow from './DropdownArrow';
// Utils
import myStyled from '../../../services/myStyled';
// geoOptions
import getGeoOptions from './getGeoOptions';

const StyledGeoDropdown = myStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: 40,
  position: 'relative',
});

const StyledTitle = myStyled('label', {
  color: 'text.base',
  fontFamily: 'baseBold',
  mb: 4,
  textAlign: 'left',
});

const StyledDropdownContainer = myStyled('div', (props) => ({
  alignItems: 'center',
  backgroundColor: 'neutrals.lightest',
  border: '1px solid',
  borderColor: props.error ? 'cta.error' : 'neutrals.light',
  borderRadius: 'form',
  cursor: 'pointer',
  display: 'flex',
  height: '2.875rem',
  justifyContent: 'space-between',
  position: 'relative',
  px: 16,
  py: 8,
}));

const StyledItem = myStyled('div', {
  alignItems: 'center',
  display: 'flex',
});

const StyledImg = myStyled('img', {
  height: '1.875rem',
  width: '1.875rem',
});

const StyledSelectedValue = myStyled('p', (props) => ({
  color: 'text.light',
  mx: props.hasIcon ? 12 : -4,
  my: 0,
}));

const StyledValue = myStyled('p', {
  color: 'text.light',
  mx: 12,
  my: 0,
});

const StyledItemsContainer = myStyled('div', (props) => ({
  backgroundColor: 'neutrals.lightest',
  borderStyle: 'solid',
  borderRadius: 'form',
  borderColor: props.opened ? 'neutrals.light' : 'transparent',
  borderWidth: props.opened ? '1px' : '0px',
  boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.07)',
  left: 0,
  maxHeight: props.opened ? '1000px' : '0px',
  overflow: 'hidden',
  padding: 10,
  pointerEvents: props.opened ? 'auto' : 'none',
  position: 'absolute',
  py: props.opened ? 10 : 0,
  top: 'calc(100% + 5px)',
  transition: props.opened
    ? 'max-height 0.5s ease-in, padding 0.3s ease, border-color 0.2s ease-in'
    : 'max-height 0.4s ease-out, padding 0.7s ease, border-color 0.6s ease-out',
  width: '100%',
  zIndex: 1,
}));

function GeoDropdown({ placeholder, setValue, value, error, errorMessage, name }) {
  const [opened, setOpened] = useState(false);
  const geoOptions = useMemo(() => getGeoOptions({ placeholder, value }), [placeholder, value]);

  const filteredActiveGeo = geoOptions.filter((loc) => loc.repLink !== value);

  const findActiveGeo = geoOptions.find((loc) => loc.repLink === value);

  const handleChangeGeo = (repLink, countryName) => () => {
    setValue({ url: repLink, formattedString: countryName }, name);
  };

  return (
    <StyledGeoDropdown>
      <StyledTitle>Select country</StyledTitle>

      <StyledDropdownContainer onClick={() => setOpened(!opened)} error={error}>
        <StyledItem>
          {findActiveGeo?.icon && <StyledImg src={findActiveGeo?.icon} />}

          <StyledSelectedValue hasIcon={findActiveGeo?.icon}>
            {findActiveGeo?.name}
          </StyledSelectedValue>
        </StyledItem>

        <DropdownArrow opened={opened} />

        <StyledItemsContainer opened={opened}>
          {filteredActiveGeo.map((loc) => {
            return (
              <StyledItem
                onClick={handleChangeGeo(loc.repLink, loc.name)}
                key={loc.name}
                px={6}
                py={10}
              >
                <StyledImg src={loc.icon} />

                <StyledValue>{loc.name}</StyledValue>
              </StyledItem>
            );
          })}
        </StyledItemsContainer>
      </StyledDropdownContainer>

      <ErrorMessage show={error}>{errorMessage}</ErrorMessage>
    </StyledGeoDropdown>
  );
}

GeoDropdown.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setValue: PropTypes.func,
};

export default GeoDropdown;
