import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
// Core
import Button from '../core/Button/Button';
// Utils
import myStyled from '../../services/myStyled';
// Assets
import factsData from './facts';

const animationSteps = {
  enter: {
    x: -500,
    opacity: 0,
  },
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    x: 500,
    opacity: 0,
  },
};

const Container = myStyled('div', {
  position: 'relative',
  alignSelf: 'center',
});

const StyledFunFactGenerator = myStyled('section', {
  backgroundColor: 'secondary.base',
  borderRadius: 'base',
  color: 'text.base',
  display: 'grid',
  gridTemplateRows: '1fr min-content',
  minHeight: '400px',
  mx: 'auto',
  position: 'absolute',
  px: 32,
  py: 40,
  textAlign: 'center',
});

const StyledFactWrapper = myStyled('div', {
  alignSelf: 'center',
});

const StyledTitle = myStyled('h2', {
  color: 'primary.base',
  fontFamily: 'headerMedium',
  fontSize: 32,
  fontWeight: 'heavy',
  lineHeight: 'tighter',
  mb: 24,
});

const StyledFact = myStyled('div', {
  fontSize: 18,
  lineHeight: 'tight',
  mb: 24,
});

function FunFactGenerator() {
  const mounted = useRef(false);
  const [activeFact, setActiveFact] = useState();
  const [remainingFacts, setRemainingFacts] = useState([]);

  // On first load a random number is generated based on factsData and that is the index for the
  // first fact to show
  useEffect(() => {
    if (!mounted?.current) {
      mounted.current = true;

      const randomIndex = Math.floor(Math.random(factsData.length) * factsData.length);
      const [active] = factsData.splice(randomIndex, 1);

      setActiveFact(active);
      setRemainingFacts(factsData);
    }
  }, []);

  // Click Handler for pulliing a new active fact
  const clickHandler = useCallback(() => {
    const remainingFactsCopy = (remainingFacts.length ? remainingFacts : factsData).slice();
    const randomIndex = Math.floor(
      Math.random(remainingFactsCopy.length) * remainingFactsCopy.length
    );

    const [active] = remainingFactsCopy.splice(randomIndex, 1);

    setActiveFact(active);
    setRemainingFacts(remainingFactsCopy);
  }, [remainingFacts]);

  return (
    <Container>
      <AnimatePresence>
        <motion.div
          key={activeFact?.index}
          variants={animationSteps}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <StyledFunFactGenerator>
            <StyledFactWrapper>
              <StyledTitle>{activeFact?.title}</StyledTitle>

              <StyledFact>{activeFact?.fact}</StyledFact>
            </StyledFactWrapper>

            <Button alignSelf="end" onClick={clickHandler}>
              Gimme Another
            </Button>
          </StyledFunFactGenerator>
        </motion.div>
      </AnimatePresence>
    </Container>
  );
}

export default FunFactGenerator;
