import React from 'react';
import PropTypes from 'prop-types';

// Utils
import myStyled from '../../../services/myStyled';

const ButtonContainer = myStyled('a', {
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  textDecoration: 'none',
  width: 'fit-content',
  mt: 14,
  mb: 10,
});
const StyledSvgWrapper = myStyled('div', {
  alignSelf: 'center',
  display: 'flex',
  flex: 0.03,
  mr: 10,
  svg: {
    display: 'flex',
    margin: 'auto',
  },
});

const StyledButtonText = myStyled('div', {
  display: 'flex',
  flex: 1,
  fontSize: 18,
  textAlign: 'center',
});

function ChevronLeftButton({ children, ...rest }) {
  return (
    <ButtonContainer {...rest}>
      <StyledSvgWrapper>
        <svg width="7" height="12" fill="FFF" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M.294 5.21 4.777.725a1.021 1.021 0 0 1 1.437 0 1.021 1.021 0 0 1 0 1.436L2.46 5.927l3.766 3.766a1.021 1.021 0 0 1-.719 1.737c-.266 0-.521-.104-.718-.3L.306 6.645a1.01 1.01 0 0 1-.012-1.437v-1Z"
            fill="#fff"
          />
        </svg>
      </StyledSvgWrapper>
      <StyledButtonText>{children}</StyledButtonText>
    </ButtonContainer>
  );
}

ChevronLeftButton.propTypes = {
  children: PropTypes.any,
};

export default ChevronLeftButton;
