import React from 'react';
// Components
import ListItem from '../ListItem';
// Utils
import myStyled from '../../../services/myStyled';
// Assets, Constants
import letterData, { END, LIST_ITEM, VALUE, VALUE_BLOCK } from './letterData';

const StyledBold = myStyled('span', {
  fontFamily: 'baseBold',
});

function useLetter(formData) {
  // Takes the formData and puts it into letterArray and returns that array filled out with the info
  const letter = letterData(formData);

  function boldValues([type, v], b, a) {
    if (type === VALUE) {
      return <StyledBold key={`styled-bold-${a}-${b}`} children={v} />;
    }
    if (type === VALUE_BLOCK) {
      return <StyledBold key={`styled-bold-${a}-${b}`} children={v} display="block" />;
    }
    return ` ${v} `;
  }

  // Simplifies (and copies) the data in letter and adds in styled components for viewing on-screen
  const letterWithComponents = letter.slice().map((fullEntry, a) => {
    const entryType = fullEntry[0][0];

    if (entryType === LIST_ITEM) {
      return <ListItem children={fullEntry.map((m, b) => boldValues(m, b, a))} />;
    }
    return fullEntry.map((m, b) => boldValues(m, b, a));
  });

  // Simplifies (and copies) the data in letter and merges it into one big string to be inserted
  // into an email
  const letterForEmail = letter
    .slice()
    .map((fullEntry) =>
      fullEntry
        .map(([type, v]) => {
          if (type === LIST_ITEM) {
            return `- ${v}`;
          }
          if (type === VALUE_BLOCK) {
            return `\n${v}`;
          }
          if (type === END) {
            return `${v} \n\n`;
          }

          return v;
        })
        .join(' ')
    )
    .join('');

  return [letterWithComponents, letterForEmail];
}

export default useLetter;
